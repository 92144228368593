
import { Options, Vue } from 'vue-class-component'
import service from '@/utils/request'
import { ApplyStatus, ContentList, DatePracticeInfo, Plan, PlanStatus, SurveyList } from '@/models/plan'
import dayjs from 'dayjs'
import { Dialog, Notify } from 'vant'
import { AuthStore } from '@/store/modules/auth'
import NaviBar from '@/components/NaviBar.vue'
import PlanTeacherDetailPracticeCard from '@/components/PlanTeacherDetailPracticeCard.vue'

@Options({
  components: {
    NaviBar, PlanTeacherDetailPracticeCard
  }
})
export default class PlanDetail extends Vue {
  isApply = false
  planId = ''
  applyStatus = ApplyStatus
  status = PlanStatus
  isInfoShow = false
  currentPractice!:DatePracticeInfo
  plan: Plan = {
    cover: '',
    createTime: '',
    endTime: '',
    id: 0,
    introduction: '',
    isAllowRetrain: false,
    name: '',
    planContentList: [],
    planSurveyList: [],
    publishStatus: '',
    startTime: ''
  }

  get isTeacher () {
    return AuthStore.isTeacher
  }

  getDateMMDD (date: string) {
    const dateStr = dayjs(date).format('M月D日')
    if (dateStr !== 'Invalid Date') {
      return dateStr
    }
    return ''
  }

  mounted () {
    this.planId = this.$route.params.planId as string
    console.log('this.planId = ', this.planId)
    this.loadData(this.planId)
  }

  loadData (planId: string) {
    service.get('/mindup/mini/plans/' + planId, {}).then(res => {
      console.log(res.data)
      this.plan = res.data
    })
  }

  tapPractice (practice: DatePracticeInfo) {
    this.currentPractice = practice
    this.isInfoShow = true
  }
}
